<template>
  <div>
    <div class="paysuccess_wrap">
      <div class="paysuccess_con">
        <h5>
          <i><img src="@/assets/images/common/pay_success.png" alt=""></i>
<!--          {{this.$route.query.payType == 1 ? '蓝灯鱼审核通过后，会尽快为您开通服务' : '您的申请已提交，审核后会通知您！'}}-->
          {{this.$route.query.tradmark ? "订单审核通过后，会尽快为您办理" : "蓝灯鱼审核通过后，会尽快为您开通服务"}}
        </h5>

        <p>
          <span class="active" @click="orderEvent">我的订单</span>
<!--          <span  @click="serverEvent">我的服务</span>-->
        </p>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
  import footers from '@/components/common/xfooter'
  export default {
    name: "paySuccess",
    data () {
      return {

      }
    },
    components: { footers },
    methods: {
      orderEvent () {
        if(this.$route.query.tradmark == 'tradmark'){
          this.$router.push({
            path: "/hostingPlatforms/myRenewal"
          })
        }else if(this.$route.query.tradmark == 'patent'){
          this.$router.push({
            path: "/hostingPlatforms/myAnnualFee"
          })
        }else {
          this.$router.push({
            path: "/personalCenter/myOrder"
          })
        }
      },
      serverEvent () {
        this.$router.push({
          path: "/personalCenter/myServer"
        })
      },
    }
  }
</script>
<style lang="less" scoped>
  .paysuccess_wrap {
    min-height: calc(100vh - 85px);
    padding: 89px 100px 9px;
    box-sizing: border-box;
    background: #f2f2f2;
    .paysuccess_con {
      width: 100%;
      min-height: calc(100vh - 183px);
      background: #fff;
      padding: 160px 0 80px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
      h5 {
        text-align: center;
        font-size: 28px;
        color: #243c5e;
        margin-bottom: 35px;
        i {
          display: inline-block;
          width: 46px;
          height: 46px;
          margin-right: 16px;
          vertical-align: middle;
          margin-top: -3px;

          img {
            display: block;
            width: 100%;
          }
        }
      }
      p {
        text-align: center;
        span {
          font-size: 16px;
          color: #333;
          padding: 0 20px;
          position: relative;
          cursor: pointer;
          &.active {
            color: @blue;
          }
          /*&:nth-child(1)::after {*/
          /*  content: "";*/
          /*  width: 1px;*/
          /*  height: 20px;*/
          /*  background: #d3d3d3;*/
          /*  position: absolute;*/
          /*  right: 0;*/
          /*  top: 50%;*/
          /*  margin-top: -10px;*/
          /*}*/
        }
      }
    }
  }
</style>
